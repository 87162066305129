*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
}
#root {
  display: flex;
}
